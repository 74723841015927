import { ButtonProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<ButtonProps> = {
	schemaType: "component",
	displayName: "Link",
	component: "Link",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "UrlField",
					title: "Url",
					key: "url",
					advanced: true,
				},
				{
					type: "TextField",
					title: "Text",
					key: "text",
					placeholder: "Link text",
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					title: "Variant",
					key: "variant",
					type: "VisualUniqueSelection",
					columns: 4,
					options: [
						{
							value: "primary",
							img: "/thumbnails/components/Button/primary2x.png",
						},
						{
							value: "secondary",
							img: "/thumbnails/components/Button/secondary2x.png",
						},
						{
							value: "tertiary",
							img: "/thumbnails/components/Button/tertiary2x.png",
						},
						{
							value: "text",
							img: "/thumbnails/components/Button/textButton2x.png",
						},
						{
							value: "textSecondary",
							img: "/thumbnails/components/Button/textButton2x.png",
						},
					],
				},
			],
		},
	],

	default: {
		component: "Button",
		url: {
			newTab: false,
			noFollow: false,
		},
		text: "",
		variant: "primary",
	},
};

export default schema;
