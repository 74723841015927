import { FeaturedAudiovisualProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	defaultLink,
	heading,
	link,
	verticalSpacing,
} from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Module<FeaturedAudiovisualProps> = {
	schemaType: "module",
	component: "FeaturedAudiovisual",
	category: "content",
	displayName: "Featured Audiovisual",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Visual Content 1",
					type: "ComponentArray",
					key: "visualContent1",
					whiteList: ["VideoComponent", "Image"],
					contentType: "components",
					maxItems: 1,
					mandatory: true,
					helptext: "Recommended minimum image size: 792x514",
				},
				{
					title: "Visual Content 2",
					type: "ComponentArray",
					key: "visualContent2",
					whiteList: ["VideoComponent", "Image"],
					contentType: "components",
					maxItems: 1,
					mandatory: true,
					helptext:
						"Recommended minimum image size: 792x514 (Only available in layout 1)",
				},
				{
					...heading,
					mandatory: true,
				},
				{
					title: "Subtitle",
					type: "TextField",
					key: "subtitle",
					mandatory: false,
					hideable: true,
					helptext: "Only available in layout  2 & 3",
				},
				{
					...richtext,
					title: "Content",
					key: "content",
					mandatory: false,
					hideable: true,
				},
				{ ...link, hideable: true },
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "UniqueCheck",
					title: "Show quote icon",
					key: "showQuoteIcon",
					options: [{ title: "true" }],
				},
				{
					type: "RadioGroup",
					title: "Content position",
					key: "videoPosition",
					helptext: "Only available in layout  2 & 3",
					options: [
						{ name: "left", value: "left", title: "Left" },
						{ name: "right", value: "right", title: "Right" },
					],
				},
				{
					type: "UniqueCheck",
					title: "Show quote icon",
					key: "showQuoteIcon",
					options: [{ title: "true" }],
				},
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout001",
							img: "/thumbnails/modules/FeaturedAudiovisual/Layouts/layout001.png",
						},
						{
							value: "layout002",
							img: "/thumbnails/modules/FeaturedAudiovisual/Layouts/layout002.png",
						},
						{
							value: "layout003",
							img: "/thumbnails/modules/FeaturedAudiovisual/Layouts/layout003.png",
						},
					],
				},
			],
		},
	],

	default: {
		component: "FeaturedAudiovisual",
		visualContent1: [
			{
				component: "VideoComponent",
			},
		],
		visualContent2: [
			{
				component: "VideoComponent",
			},
		],
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		subtitle: "Lorem Ipsum",
		content: "Lorem ipsum paragraph",
		link: {
			...defaultLink,
			text: "Saber más",
			variant: "text",
		},
		videoPosition: "right",
		anchorID: null,
		layout: "layout001",
		verticalSpacing: "medium",
	},
	thumbnails: {
		"1x": "/thumbnails/modules/FeaturedAudiovisual/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FeaturedAudiovisual/thumbnail@2x.png",
	},
};

export default schema;
