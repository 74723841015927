import { TextCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";
import { button } from "@schemas/presets/button";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Component<TextCardProps> = {
	schemaType: "component",
	component: "TextCard",
	displayName: "TextCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },
				{
					...richtext,
					title: "Subtitle",
					key: "subtitle",
				},
				{
					...richtext,
					title: "Detail",
					key: "detail",
				},
				{ ...button },
			],
		},
	],

	default: {
		component: "TextCard",
		title: { content: "Lorem ipsum", tag: "h2" },
		subtitle: "Subtitle",
		detail: "Detail",
		link: { component: "Button" },
	},

	thumbnails: {
		"1x": "/thumbnails/components/ReferenceCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/ReferenceCard/thumbnail@2x.png",
	},
};

export default schema;
